<template>
  <b-modal
    ref="modal-join-group"
    hide-title
    hide-footer
    size="lg"
    @close="$emit('refresh')"
  >
    <template #modal-header="{ close }">
      <h5>Bol/a si zaradený/á do triedy</h5>
      <svg-cross-icon @click="close()" />
    </template>
    <div class="m-5">
      <p>
        Učiteľ
        <b class="fw-bold">
          {{ creatorFullName }}
        </b>
        ťa zaradil do triedy
        <b class="fw-bold" v-if="group">
          {{ groupName }}
        </b>
      </p>
    </div>
  </b-modal>
</template>

<script>
import { getUserDisplayName } from "/utils/helpers";
import { get } from "lodash";

export default {
  components: {
    "svg-cross-icon": () => import("/assets/icons/cross.svg?inline"),
  },

  data() {
    return {
      group: null,
    };
  },

  computed: {
    creatorFullName() {
      return getUserDisplayName(get(this.group, "creator"));
    },
    groupName() {
      const name = get(this.group, "name") || "";
      const subject = get(this.group, "subject") || "";

      return `${name} - ${subject}`.trim();
    },
  },

  methods: {
    showModal(e) {
      this.group = e;
      this.$nextTick(() => {
        this.$refs["modal-join-group"].show();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}

h4 {
  font-family: Boing !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #2b2e4a;
}

::v-deep .modal-header {
  border-bottom: 0 none;
}

::v-deep .modal-content {
  top: 150px;
}

::v-deep .modal-body {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    align-items: center;

    color: #000000;
  }
  .modal-box {
    align-items: center;
    width: 569px;
    height: 94px;
    left: 617px;
    top: 847px;

    background: #fff5d1;
    border: 1px solid #e6d8a7;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .modal-box-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: -0.05em;

    color: #cab979;
  }
}
input {
  border: none;
  border-bottom: 1px solid #cadeea;
  width: 100%;
  &::placeholder {
    color: rgba(156, 161, 174, 0.4) !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }
  &:focus {
    outline: 0;
  }
  &:read-only {
    background-color: transparent;
  }
}

::v-deep .modal-content {
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0 0
    100% 0/0 0 4.53px 0 stretch;
}

::v-deep .modal-footer {
  border-top: 0 none !important;
  align-items: left;
}

.modal-btn {
  width: 221px;
  height: 54px;
  left: 782px;
  top: 767px;
}

.box-content {
  margin: auto;
  padding: 20px;
  justify-content: center;
}

.box-bottom {
  width: 100%;
  background: #fff5d1;
  border: 1px solid #e6d8a7;
  box-sizing: border-box;
  align-items: center;
  border-radius: 4px;
}
</style>
